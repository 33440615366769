:root {
  --text-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --primary-bg-color: #282c34;
  --secondary-bg-color: #575969;
}

.App {
  font-family: var(--text-font);
  text-align: center;
  background-color: var(--primary-bg-color);
  color: #fff;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
header {
  display: none;
}
header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}
.menu button {
  background: none;
  border: none;
}
nav {
  display: none;
  background-color: #4c4f5f;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.navItems {
  padding: 8px;
  background-color: #212122;
  width: 100%;
  max-width: 340px;
}
.navPromptWrapper {
  border-bottom: 1px solid #ffffff33;
  // padding: 10px;
  // height: calc(100vh - 460px);
  overflow-y: hidden;
  overflow-x: hidden;
}

// use this height for normal user
.navPromptWrapperMinHeight {
  height: calc(100vh - 380px);
}

// use this height for admin, superadmin user
.navPromptWrapperMaxHeight {
  height: calc(100vh - 460px);
}

.navPrompt {
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 20px;
  margin: 5px 0;
  // width: 94%;
  text-align: left;
  // border: 1px solid blue;
}
.navPrompt a {
  display: flex;
  align-items: center;
  column-gap: 20px;
  text-decoration: none;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
}

/* Modal Box */
.modal123 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content-123 {
  background-color: rgb(33, 37, 41);
  margin: 6% auto;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  height: 60%;
}

.close-123 {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-123:hover,
.close-123:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.thread {
  position: relative;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  // overflow: hidden;
  padding: 0.5rem;
  // margin: 5px 0;
  text-align: left;
  animation: fadeInChar 1s ease-in all;
}

.thread p {
  color: #ececf1;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}

.thread_icon_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8e8ea0;
  color: #565869;
  outline: none;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.25rem;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  box-sizing: border-box;
}

.thread_icon_button:hover {
  color: #8e8ea0;
  background-color: transparent;
}

.thread_drop_button {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  color: #000;
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.thread:hover {
  cursor: pointer;
  background-color: #202123;
}

.active_thread {
  background-color: #202123;

  p {
    width: calc(100% - 2.25rem);
  }
}

.active_navPrompt {
  background-color: hsla(240, 9%, 59%, 0.1);
}

.navPrompt {
  border-radius: 8px;
  font-size: 14px;
}

.navPrompt button {
  display: flex;
  align-items: center;
  column-gap: 20px;
  text-decoration: none;
}
.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #ececf1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
}
.outer-div {
  // display: flex;
  // height: 85vh;
  // flex-wrap: wrap;
  height: 100%;
}

.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}
#botMessage pre,
#chatPrompt pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
  // top:5px;
}
pre {
  font-family: var(--text-font);
}
@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navCloseIcon {
  width: 20%;
  margin: 10px;
}
nav svg {
  float: left;
}
// .sideMenu {
//   width: 260px;
//   padding: 8px;
//   background-color: #202022;
//   height: 100%;
// }
// .sideMenuButton {
//   border: 1px solid hsl(0deg 0% 100% / 20%);
//   border-radius: 5px;
//   padding: 10px;
//   text-align: left;
//   font-size: 14px;
//   color: #d1d5db;
//   transition: all 0.25s ease;
//   -webkit-transition: all 0.25s ease;
//   -moz-transition: all 0.25s ease;
//   -o-transition: all 0.25s ease;
// }
.navPrompt:hover {
  cursor: pointer;
  background-color: hsla(240, 9%, 59%, 0.1);
}
// .navPrompt:hover,
// .sideMenuButton:hover {
//   cursor: pointer;
//   background-color: hsla(240, 9%, 59%, 0.1);
// }
// .sideMenuButton span {
//   font-size: 20px;
//   font-weight: 500;
//   padding-left: 6px;
//   padding-right: 10px;
// }
.chatBox {
  // flex: 1;
  // flex-grow: 1;
  background-color: #353540;
  // background-color: #222299;
  position: relative;
  line-height: 24px;
  width: 50vw;
  height: 100%;
  width: 100%;
  // height: 85.2vh;
  // height: 90vh;
  // top: px;
  color: #d1d5db;
  font-size: 16px;
}
#introsection::before,
#introsection::after {
  float: left;
  color: rgb(0, 134, 244);
}
#introsection {
  text-align: left;
  padding: 20px;
  max-width: 50rem;
  margin: auto;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#introsection h1 {
  padding-top: 20px;
  font-size: 32px;
  margin-bottom: 20px;
}
#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}
#introsection ul {
  list-style-type: square;
}
#introsection pre {
  margin: 0;
  display: inline;
}

#introsection li {
  font-size: small;
}
#introsection ul li {
  padding: 15px !important;
  background: #41404d;
  border: 1px solid #41404d;
}
.chatLogWrapper {
  // height: 74vh;
  height: 80vh;
  overflow-y: auto;
}

@media screen and (max-width: 1366px) {
  .chatLogWrapper {
    height: 70vh;
  }
}

.promptBox {
  height: fit-content;
  // max-height: 85vh;
  overflow-y: auto;
  background-color: rgb(53, 53, 64);
  // background-color: #fffbfb;
  width: 35vw;
}
/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 0px;
  background-color: #575969;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPromptWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.userSVG {
  transform: scale(0.6);
}
.botMessageMainContainer {
  width: 100%;
  background-color: #444654;
  position: relative;
}
.botMessageWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}
.stop-messgage {
  position: absolute;
  bottom: 10px;
  right: 100px;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10%;
  border: 1px solid rgb(86, 88, 105);
  background-color: rgb(52, 53, 65);
  color: rgb(217, 217, 217);
  cursor: pointer;
}
.stop-messgage:hover {
  background-color: rgb(64, 65, 79);
  /* border: none; */
}
.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}
#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.inputPromptWrapper {
  margin-top: 40px;

  left: 50%;
  right: 50%;
  bottom: 0;
  position: relative;
  transform: translate(-50%, -10%);
  width: 80%;
  max-height: 200px;
  height: 52px;
  background-color: #41414e;
  // background-color: #111155;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
}

.inputPrompttTextarea-container {
  position: relative;
  max-width: 48rem;
  margin: 0 auto;
  top: 95px;
}
.inputPrompttTextarea {
  padding: 10px 20px;
  flex: 1;
  resize: none;
  background-color: #41414e;
  color: #fff;
  font-size: 1rem;
  line-height: 1.8rem;
  outline: none;
  border: none;
  overflow-y: auto;
  border-radius: 10px;
  height: 49px;
  max-height: 200px;
  width: 92%;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.inputPrompttTextarea::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

.inputPrompttTextarea::-webkit-scrollbar-track {
  background-color: #41414e;
}

.inputPrompttTextarea::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 4px;
  cursor: pointer;
}

.inputPrompttTextarea::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}
#input-button {
  background: rgb(65, 65, 78);
  border: none;
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 35px;
  height: 45px;
  border-radius: 50%;
}

.CustomNewButton {
  padding: 0px;
  width: auto !important;
  height: auto !important;
  padding-top: 3px;
}

// assistant styles
#assistantScrollableDiv {
  height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

.assistantChatBox {
  // flex: 1;
  // flex-grow: 1;
  background-color: #353540;
  // background-color: #222299;
  position: relative;
  line-height: 24px;
  // width: 50vw;
  // height: 100%;
  width: 100%;
  // height: 85.2vh;
  height: calc(100vh - (56.64px + 36px));
  // top: px;
  color: #d1d5db;
  font-size: 16px;
}

.assistantChatHeadWrapper {
  margin-top: 8px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  right: 50%;
  bottom: 0;
  position: relative;
  transform: translate(-50%, -10%);
  max-width: 400px;
  // max-height: 200px;
  background-color: #41414e;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
}

.AssistantScrollUpButton {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 100%;
  font-size: 12px;
  position: absolute;
  bottom: 18vh;
  right: 50%;

  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GptScrollUpButton {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 100%;
  font-size: 12px;
  position: absolute;
  bottom: 14vh;
  right: 50%;

  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assistantInputPromptWrapper {
  margin-top: 15px;
  left: 50%;
  right: 50%;
  bottom: 0;
  position: relative;
  transform: translate(-50%, -10%);
  // width: 80%;
  width: 48rem; 
  resize: none;
  // max-height: 200px;
  min-height: 100px;
  background-color: #41414e;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
}

.assistantInputFilesContainer {
  position: absolute;
  top: -34px; // 30px button height
  left: 0;
  display: flex;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
}

.assistantInputFile {
  padding: 2px 6px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  small {
    font-size: 10px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 4px;
    font-size: 12px;
  }
}

.assistantInputPromptTextarea {
  padding: 10px 20px;
  flex: 1;
  resize: none;
  background-color: #41414e;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  overflow-y: auto;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #888888 #41414e;
}

.assistantInputPromptTextarea::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

.assistantInputPromptTextarea::-webkit-scrollbar-track {
  background-color: #41414e;
}

.assistantInputPromptTextarea::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 4px;
  cursor: pointer;
}

.assistantInputPromptTextarea::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}

#assistant-input-button {
  background: rgb(65, 65, 78);
  border: none;
  position: absolute;
  top: 20%;
  right: 15px;
  width: 35px;
  height: 45px;
  border-radius: 50%;
}

.assistantCustomNewButton {
  padding: 0px;
  width: auto !important;
  height: auto !important;
  padding-top: 3px;
}

.assistantUploadButton {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  // margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
}

// Adn Css
.customEditButton {
  cursor: pointer;
}
.customDeleteButton {
  cursor: pointer;
}

.editButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding-bottom: 10px;
}
.navButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  // padding-bottom: 10px;
}

/* Define the textarea styles */
.editPromptTextArea {
  resize: none;
  width: 53vw;
  background-color: transparent;
  border: none;
  color: white;
}

@media (hover: hover) {
  button:hover {
    cursor: pointer;
    background: #212023;
    // border-radius: 20%;
  }
}

@media screen and (min-width: 768px) {
  .inputPromptWrapper {
    position: absolute;
  }
}

@media screen and (max-width: 1024px) {
  .App {
    display: block;
  }
  header {
    display: flex;
    align-items: center;
    background: #353441;
    // border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;
  }
  nav {
    display: flex;
  }
  // .sideMenu {
  //   display: none;
  // }
  .chatBox {
    position: static;
  }
  .chatPromptWrapper {
    padding: 12px;
  }
  .botMessageWrapper {
    padding: 12px;
  }
  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 16px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
}

@media screen and (min-width: 1536px) {
  //   .loginContainer h1 {
  //     font-size: 40px;
  //   }
  //   .loginContainer p {
  //     font-size: 25px;
  //   }
  //   #loginButton {
  //     font-size: 22px;
  //   }
}
.predefinedprompts-container::-webkit-scrollbar {
  width: 2px; /* width of the entire scrollbar */
  border-radius: 50%;
}

.predefinedprompts-container::-webkit-scrollbar-track {
  background: #41404d; /* color of the tracking area */
}

.predefinedprompts-container::-webkit-scrollbar-thumb {
  background: #888; /* color of the scroll thumb */
}

.predefinedprompts-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scroll thumb on hover */
}
.predefinedprompts-container {
  margin: 0 auto;
  padding: 0 10px 0 0;
  max-width: 924px;
  max-height: 235px;
  overflow: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}
.predefinedprompts-container div ul {
  padding: 0;
}
.btn.btn-secondary.plus-btn {
  background-color: #41404d !important;
  border: none;
  height: 49px;
  bottom: 0 !important;
  width: 48px;
  border-radius: 10px;
  color: #777;
}
.btn.btn-secondary.plus-btn:hover {
  color: #fff;
}

.selectedTagContainer {
  position: absolute;
  bottom: -1.8rem;
  display: flex;
  gap: 5px;
}

.tagSection {
  width: 700px;
  display: flex;
  justify-content: end;
}

.tag {
  font-size: 10px;
  border: 1px solid gray;
  padding: 0px 5px;
  border-radius: 3px;
  margin-left: 5px;
}

/* DROPDOWN CSS */
.show.dropup {
  display: block;
}
.dropup .dropdown-toggle::after {
  display: none;
}
.user-btn {
  background: #222529 !important;
  border: none !important;
  display: flex;
  column-gap: 20px;
  padding: 12px;
  color: white;
  border-radius: 7px;
  margin-top: 10px;
}
.user-btn:hover {
  background: #222529 !important;
}

.thread-search{
  background-color: #444654;
  color: gray;
  border: none;
}

.no-data-found {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;  
}


.custom-panel-style{
  // background: #dd2f2f;
  
}

.show-more-loading-container{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
// Styles for Templates
}


.templates-page{
  width: 100%;
  height: 100%;
  background-color: #353540;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assistants-menu-container{
  max-height: 50vh;
  overflow-y: auto;
}

.custom-show-btn{
  display: flex;
  align-items: center;
  gap: 1.7rem;
  width: 100%;
  color: whitesmoke;
  background: transparent;
  border: none;
  height: 40px;
  border-radius: 7px;
  padding-left: 10px;
}

.custom-show-btn-light{
  display: flex;
  align-items: center;
  gap: 1.7rem;
  width: 100%;
  color: whitesmoke;
  background: #28272f;
  border: none;
  height: 40px;
  border-radius: 7px;
  padding-left: 10px;
}

.custom-show-btn-light p {
  padding: 0px;
  margin: 0px;
  font-size: 15px;
}

.custom-show-btn p {
  padding: 0px;
  margin: 0px;
  font-size: 15px;
}
.templates-container{
  width: 60%;
  height: 60vh;
  background-color: #00000e;
  // border-radius: 1rem 0px 0px 1rem;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
  border-radius: .3rem;
  padding: 1.5rem .8rem;
  color: gray;
  overflow-y: auto;
  
}

#table-response-container {
  max-width: 44rem;
  overflow-x: auto;
}

#table-response-container table,
#table-response-container th, 
#table-response-container td {
  border: 1px solid white;
}

.templates-loading-container { 
  min-height: 300px; 
  max-height: 400px; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.template-description-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.template-content-container {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 1rem;
}

.assistantsConversationStarterWrapper{
    margin-top: 40px;
    left: 50%;
    right: 50%;
    bottom: 0;
    position: relative;
    transform: translate(-50%, -10%);
    width: 48rem; 
    resize: none;
    max-height: 110px;
    min-height: 110px;
    border-radius: 5px;
    display: flex;
    overflow-y: scroll;
    
}

.conversation-starter-box {
  width: 100%;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
}

.conversation-starter {
  width: 22.6rem;
  margin: 5px;
  padding: 5px 8px;
  border: 1px solid gray;
  border-radius: 7px;
  background-color: #41414e;
  display: flex;
  align-items: center;
}

.conversation-starter:hover{
  background-color: rgb(118, 116, 119);
  cursor: pointer;
}

.conversation-starter :hover  .conversation-starter-icon{
  display: block;
  color: #f0f5f5;
}

.conversation-starter p {
  margin: 0px;
  padding: 0px;
}

.conversation-contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.conversation-starter-icon{
  color: white;
  font-size: 18px;
  display: none;
}

.conversation-starter-icon:hover{
  color: white;
  font-weight: bold;
}

.assistant-info-container-on-chat-page{
  max-width: 450px;
  max-height: 350px;
  overflow-y: auto;
  // background: blue;
  padding: 1rem;
  margin: -3.5rem auto;
  text-align: center;
}

.assistant-info-container-on-chat-page p {
  padding: 0px;
  // margin: 0px;
}

.assistant-creator {
  color: gray;
  margin-bottom: .7rem;
}
#table-response-container ol,
#table-response-container ul {
    margin: 0;
    line-height: 0.5;
}

#table-response-container li {
    line-height: 1; 
    margin: 0;
}

#table-response-container li p,
#table-response-container li {
    line-height: 1.25; 
    margin: 0;
}

.bot-response-markdown-container img {
  display: block;
  width: 100%;       
  height: auto;      
  object-fit: cover; 
  border-radius: 8px; 
  margin-bottom: 1rem; 
}


.citations-container {
    padding: 2rem;
    backdrop-filter: blur(21px) saturate(121%);
    -webkit-backdrop-filter: blur(21px) saturate(121%);
    background-color: rgba(17, 25, 40, 0.21);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.citation-download-link {
    display: inline-block;
}

  
  